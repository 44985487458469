// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-tsx": () => import("./../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-about-tsx": () => import("./../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-work-tsx": () => import("./../src/templates/work.tsx" /* webpackChunkName: "component---src-templates-work-tsx" */),
  "component---src-templates-works-tsx": () => import("./../src/templates/works.tsx" /* webpackChunkName: "component---src-templates-works-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

